/* Base styles for maps */
@import 'leaflet/dist/leaflet.css';
@import 'assets/styles/partials/base/variables';

.leaflet-pane {
  z-index: 1;
}

/* Controls */
.leaflet-container {
  z-index: 1;
  min-height: 30vh;

  .leaflet-bar {
    background-color: $body-background-color;
    overflow: hidden;

    &.leaflet-control-zoom {
      border-radius: .3125rem .3125rem 0 0;
      margin-top: 1.5rem;
    }
  }

  .leaflet-control {
    margin-top: 0;
    border: 0;

    &:not(.colorbar) {
      margin-left: 1.5rem;
    }

    &:not(.search-bar-control) {
      box-shadow: $map-shadow;
    }

    a {
      width: 2.25rem;
      height: 2.25rem;
      border-bottom: 0;
    }
  }

  /* Custum Controls */
  .leaflet-control-center,
  .leaflet-control-fullscreen {
    width: 2.25rem;
    height: 2.25rem;
    background-color: $body-background-color;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
    }

    &:hover {
      background-color: $grey92;
      cursor: pointer;
    }
  }

  .leaflet-control-center {
    border-radius: 0;

    &::before {
      @include icon('map-center', $grey50, 1.5rem);
    }
  }

  .leaflet-control-fullscreen {
    border-radius: 0 0 .3125rem .3125rem;

    &::before {
      @include icon('expand', $grey50, 1.25rem);
    }
  }
}

.leaflet-control {
  a {
    &.leaflet-control-zoom-in,
    &.leaflet-control-zoom-out {
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
      }

      &:hover {
        background-color: $grey92;
      }
    }

    &.leaflet-control-zoom-in {
      &::before {
        @include icon('plus', $grey50, 1rem);
      }
    }

    &.leaflet-control-zoom-out {
      &::before {
        @include icon('minus', $grey50, 1rem);
      }
    }
  }
}

.colorbar {
  background: $transparent-white;
  box-shadow: $map-colorbar-shadow;
  padding: .4rem .4rem 0;
  border-radius: .3rem;
  margin-left: 0;

  &.columns:last-child {
    margin-bottom: .5rem;
  }

  .element-colorbar {
    width: 2.5rem;
    height: 1.5rem;
  }
}

.container-map {
  position: relative;

  .is-icon-selected {
    z-index: 1000 !important;
  }

  .marker-tooltip-title {
    font-size: 1rem;

    &.bold {
      font-weight: 600;
    }
  }

  .impact-tooltip-title {
    font-weight: 400;
    border-bottom: $border-modal;
  }

  .tooltip-container {
    max-width: 22rem;
    min-width: 10rem;
  }

  .marker-tooltip-analytic-title {
    font-weight: 600;
    display: inline-block;
    overflow: hidden;
    white-space: normal;
    min-width: 9rem;
    max-width: 18rem;
    border-bottom: .06px solid $shadow-alpha-20;
  }

  .marker-tooltip-impact-title {
    font-weight: 600;
    width: 10rem;
    display: inline-block;
    overflow: hidden;
    white-space: normal;
    margin-right: 1rem;
  }

  .marker-tooltip-impact-number {
    display: inline-block;
    overflow: hidden;
    white-space: normal;
  }

  .search-bar-control {
    position: absolute;
    top: 5%;
    z-index: 1;
    left: 10%;
    width: 75%;
    max-width: 38rem;

    .button {
      color: $blue;
    }

    >.input-search {
      width: 100%;
    }

    .input {
      background-color: $white;
    }
  }
}

.marker-permuted {
  color: $permuted-counter;
}

.marker-not-permuted {
  color: $unpermuted-counter;
}

.marker-hovered {
  filter: brightness(1.3) drop-shadow(0 .15rem 0 $grey20);
}

.legend-permuted {
  background-color: $permuted-counter;
}

.legend-unpermuted {
  background-color: $unpermuted-counter;
}

.legend-header {
  text-align: center;
  width: 4.5rem;
  white-space: pre-wrap;
}
