@import 'assets/styles/partials/base/variables';

/* Login */
.login-page {
  background-image: url('/assets/img/background/village.png'), -webkit-image-set(url('/assets/img/background/village.png') 1dppx, url('/assets/img/background/village@2x.png') 2dppx);
  background-size: cover;

  .login-title {
    margin: 17.5% 12.7% auto;
    color: $login-title-color;
    font-size: 3.5rem;
    line-height: 4rem;
    text-align: center;

    strong {
      color: $login-title-color;
    }
  }

  .login-label {
    height: 10rem;
    width: 10rem;
    margin: auto 6.3% 6.3% auto;
  }

  .login-logo-languages {
    width: 83.28%;
    margin: auto 0 0 auto;
  }

  .login-language-block {
    height: 4rem;
    width: 4rem;
    color: $primary;
    font-size: 1.5rem;
    line-height: 1.813rem;
    text-align: center;
  }

  .login-block {
    overflow: hidden;
    min-width: 640px;
    max-width: 800px;
    background-color: $white;

    .logo {
      width: 100%;
    }
  }

  .login-form-placement {
    margin: 4.875rem auto auto;
    width: 66.56%;
  }
}
