/* Design system */
.design-system-container {
  height: 100%;
  overflow-y: scroll;

  .map {
    height: 18.75rem;
  }
}

.icon-card {
  font-size: .875rem;
  text-align: center;

  .custom-icon {
    width: 2rem;
    height: 2rem;
    margin: 0 auto;
  }
}
