@import 'assets/styles/partials/base/variables';
@import 'assets/styles/partials/base/mixins';

.mapping-container {
  &.sidebar-open {
    width: calc(100% - $width-mapping-sidebar);
  }

  .map-dropdown-container {
    position: absolute;
    margin-left: 1.5rem;
    margin-top: 3rem;
    top: 5%;
    z-index: 1;
    left: 10%;
    font-size: .8rem;
    opacity: 100%;
    padding: .5rem;
    border-radius: .5rem;

    .field-label {
      font-weight: 700;
    }

    select {
      background-color: $white;
    }
  }

  .sidebar-section {
    position: fixed;
    right: 0;
    top: $header-height;
    display: flex;
    height: calc(100% - 3rem);
    z-index: 2;
    overflow-y: auto;
    transform: translateX($width-mapping-sidebar);
    transition: transform 120ms ease-out;

    &.is-open {
      transform: translateX(0%);
    }
  }

  .counter-item {
    min-width: 50%;
    color: $grey20;

    .custom-icon {
      width: 1rem;
      color: $primary;
      flex-shrink: 0;
    }
  }

  .item-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: .75rem;
  }

  .side-bar {
    max-width: 100%;
    word-break: break-all;

    .sidebar-title {
      line-height: 1.2;
    }

    .use-case-action {
      margin-bottom: .5rem;
    }

    .use-case-link {
      font-size: 1.25rem;

      &::before {
        @include icon('arrow-left-circle', $link, 1rem);

        content: '';
        width: 1rem;
        height: 1rem;
        display: block;
        margin-right: .5rem;
      }

      &.is-disabled-link {
        color: $disabled-text;
      }
    }
  }

  /* /mapping use case popup */
  .mapping-popup {
    margin-bottom: 0;
    font-size: 1rem;

    .leaflet-popup-tip-container {
      left: -.625rem;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
    }

    .leaflet-popup-tip {
      box-shadow: $map-popup-tip-shadow;
    }

    .leaflet-popup-content-wrapper {
      box-shadow: $map-popup-shadow;
      border-radius: .3125rem;
      background-color: $body-background-color;
    }

    .leaflet-popup-content {
      padding: .375rem .5rem;
      margin: 0;
    }

    .use-case-link {
      padding: .125rem .5rem;
      background-color: transparent;

      &:not(.is-disabled-link) {
        transition: all 120ms ease-out;
        color: $text;

        &:hover {
          background-color: $primary;
          color: $body-background-color;
        }
      }

      &.is-disabled-link {
        color: $disabled-text;
      }
    }
  }
}
