@import 'assets/styles/partials/base/variables';

/* Login */
.login-form {
  .login-subtitle {
    color: $grey20;
    font-size: 1.875rem;
    line-height: 2.25rem;
    text-align: left;
  }

  .login-welcome-phrase {
    margin-top: 1.438rem;
    color: $light-blue-grey;
    font-size: 1.125rem;
    line-height: 1.375rem;
    text-align: left;
  }

  .login-username-input {
    margin-top: 3rem;
  }

  .control.has-icons-right .icon {
    color: $light-blue-grey;
  }

  .login-input {
    height: 3rem;
    background-color: $input-background-color;
    border: unset;
    box-shadow: none;
    border-radius: unset;
    font-size: 1rem;
    text-align: left;
    padding-left: 1rem;
  }

  .login-input::placeholder {
    color: $input-placeholder-color;
  }

  .login-button {
    background-color: $primary;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
    border-radius: 1.75rem;
    width: 12.5rem;
    height: 3rem;
  }

  .no-account {
    color: $light-blue-grey;
  }

  .login-copyright {
    color: $input-placeholder-color;
  }
}
