@import 'assets/styles/partials/base/variables';

.has-calendar-button {
  .tab-primary {
    > .tab-panel {
      margin-right: 5rem;
    }
  }

  .graphs-container {
    height: $height-analysis-graph-container;
  }
}

.calendar-button {
  position: absolute;
  z-index: 1;
  text-align: center;
  margin-top: 1.5rem;
  right: 2rem;
}

.graphs-container {
  height: calc(#{$height-analysis-graph-container} - #{date-filter-container-height});
  max-width: 90vw;
}
