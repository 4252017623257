@import 'assets/styles/partials/base/variables';

.info-icon-default {
  color: $info-icon-default-color;
  transition: color $info-fade;
}

.info-icon-blink {
  color: $info-icon-blink-color;
  transition: color $info-fade;
}

.container-icon {
  display: flex;
  padding-left: .75rem;
  position: relative;
}
