@import 'assets/styles/partials/base/variables';

.split-column {
  width: 100%;

  .has-top-separator {
    border-top: .0625rem solid $dashboard-border-color;
  }

  .template-slot {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    overflow: hidden;
  }
}
